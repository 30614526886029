<template>
    <div class="adresse">
        <div class="adresse-selecteur" v-if="!readonly && (afficheSelecteur || afficheSauvegarde)">
            <div class="adresse-sauve" v-if="!readonly && memorisation">
                <btn-theme small @click="afficheDialogueSauveAdresse" title="Sauvegarder cette adresse">
                    <v-icon>{{ mdiContentSave }}</v-icon>
                </btn-theme>
            </div>
            <div>
                <v-select
                    dense
                    :items="listeAdressesPourSelect"
                    v-model="adresseSelectionnee"
                    v-if="afficheSelecteur"
                ></v-select>
            </div>
            <div class="spacer"></div>
            <slot></slot>
        </div>
        <div v-else class="sans-selecteur">
            <slot></slot>
        </div>

        <v-text-field dense class="obligatoire" v-model="adresse.intitule" label="Intitule"  :disabled="readonly"></v-text-field>
        <v-text-field dense class="obligatoire" v-model="adresse.nom" label="Nom" :disabled="readonly"></v-text-field>
        <v-select dense :items="['', 'M.', 'Mme']" v-model="adresse.civilite" label="Civilité" :disabled="readonly"></v-select>
        <v-text-field dense v-model="adresse.prenom" label="Prénom" :disabled="readonly"></v-text-field>
        <v-text-field dense class="obligatoire" v-model="adresse.email" label="Courriel" :disabled="readonly"></v-text-field>
        <v-text-field dense class="obligatoire" v-model="adresse.tel1" label="Téléphone mobile" :disabled="readonly"></v-text-field>
        <v-text-field dense v-model="adresse.tel2" label="Téléphone fixe" :disabled="readonly"></v-text-field>
        <v-select     dense class="obligatoire" label="Type" :items="typeadresse" v-model="adresse.type" :disabled="readonly" v-show="!masqueTypeSelecteur"></v-select>
        <v-text-field dense class="obligatoire" v-model="adresse.societe" label="Etablissement/Société" :disabled="readonly"></v-text-field>
        <v-text-field dense class="obligatoire" v-model="adresse.adresse1" label="Adresse (n° et nom de la voie)" :disabled="readonly"></v-text-field>
        <v-text-field dense v-model="adresse.adresse2" label="Complément d'adresse (batiment, étage)" :disabled="readonly"></v-text-field>
        <v-text-field dense v-model="adresse.adresse3" label="Complément d'adresse (lieu dit)" :disabled="readonly"></v-text-field>
        <v-text-field dense class="obligatoire" v-model="adresse.codepostal" label="Code postal" :disabled="readonly"></v-text-field>
        <v-text-field dense class="obligatoire" v-model="adresse.ville" label="Ville" :disabled="readonly"></v-text-field>
        <v-select     dense label = "Pays" :items="listePaysPourSelect" v-model="adresse.codepays" :disabled="readonly"></v-select>

        <!-- Dialogue de sauvegarde de l'adresse -->
        <v-dialog
            width="450"
            v-model="sauveAdresseDialogueOuvert"
        >
            <v-card>
                <v-card-title>
                    <bandeau-titre class="dialog-title">
                        Sauvegarde de l'adresse
                    </bandeau-titre>
                </v-card-title>
                <v-card-text class="dialog-body">
                    <div class="row">
                        <v-col class="py-0">
                            <v-text-field
                                label="Intitulé de l'adresse"
                                class="obligatoire"
                                v-model="adresse.intitule"
                            ></v-text-field>
                        </v-col>
                    </div>
                    <div class="row">
                        <v-col class="py-0">
                            <v-checkbox
                                label="Définir l'adresse par défaut"
                                color="black"
                                class="mt-0"
                                true-value="1"
                                false-value="0"
                                v-model="adresse.defaut"
                            ></v-checkbox>
                        </v-col>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <btn-theme @click="cacheDialogueSauveAdresse">
                        Annuler
                    </btn-theme>
                    <v-spacer/>
                    <btn-theme @click="sauveAdresse">
                        Sauvegarder
                    </btn-theme>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            top
            right
            :timeout="-1"
            :color="informationRequete.status"
            v-model="informationRequete.ouvert"
        >
            <div v-html="informationRequete.texte"></div>

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="fermeInformationRequete"
                >Fermer</v-btn>
            </template>
        </v-snackbar>

    </div>
</template>
<script>

import Api from '../../api/api.js';
import BandeauTitre from "../theme/BandeauTitre";
import BtnTheme from "../theme/BtnTheme";
import { mdiContentSave } from "@mdi/js";

const
    ADRESSE_DEFAUT = {
        intitule: '',
        numero_client: '',
        societe: '',
        civilite: '',
        prenom: '',
        nom: '',
        adresse1: '',
        adresse2: '',
        adresse3: '',
        codepostal: '',
        ville: '',
        codepays: 'FR',
        tel1: '',
        tel2: '',
        email: '',
        type:'livraison',
        defaut: 0
    },
    CARACTERES_TELEPHONE_AUTORISES = [
        '0', '1', '2', '3', '4',
        '5', '6', '7', '8', '9',
        '.', '+', ' '
    ],
    LISTE_ADRESSES_DEFAUT = [{ id: null, intitule: '< Nouvelle adresse >'}];

export default {
    name: 'Adresse',
    components: {
        BandeauTitre,
        BtnTheme
    },
    data: () => ({
        urlGetPays: process.env.VUE_APP_API_URL + '/adresse/listepays',
        urlGetAdresse: process.env.VUE_APP_API_URL + '/adresse',
        urlSauveAdresse: process.env.VUE_APP_API_URL + '/adresse/sauve',
        adresse: Object.assign({}, ADRESSE_DEFAUT),
        listePays: { FR: 'France' },
        listeAdresses: Object.assign([], LISTE_ADRESSES_DEFAUT),
        adresseSelectionnee: null,
        sauveAdresseDialogueOuvert: false,
        informationRequete: {
            ouvert: false,
            texte: '',
            status: '',
        },
        typeadresse:['livraison','facturation'],
        mdiContentSave
    }),
    props: {
        type: {             //défini le type de l'adresse
            type: String,
            required: true
        },
        memorisation: {     //affiche (true) ou cache (false) le bouton de sauvegarde
            type: Boolean,
            default: true
        },
        masqueSelecteur: {  //cache le selecteur (true) ou l'affiche (false)
            type: Boolean,
            default: false
        },
        masqueTypeSelecteur: {
            type: Boolean,
            default: false
        },
        readonly: {         //affiche l'adresse en lecture seule
            type: Boolean,
            default: false
        },
        utiliserDefaut: {   //selectionne l'adresse par défaut
            type: Boolean,
            default: true
        },
        value: {
            type: Object
        }
    },
    watch: {
        value: {
            handler (val) {
                if (typeof val === 'object' && val !== null && Object.keys(val).length > 0) {
                    let valueAdr = Object.assign({}, val);
                    if (JSON.stringify(valueAdr) !== JSON.stringify(this.adresse)) {
                        this.adresse = Object.assign({}, val);
                    }
                }
            },
            deep: true,
            immediate: true
        },
        adresse: {
            handler (adresse) {
                this.emitAdresse(adresse);
            },
            deep: true
        },
        'adresse.tel1' (newVal) {
            this.$nextTick(() => this.adresse.tel1 = this.normaliseTelephone(newVal));
        },
        'adresse.tel2' (newVal) {
            this.$nextTick(() => this.adresse.tel2 = this.normaliseTelephone(newVal));
        },
        adresseSelectionnee (adresse) {
            this.adresse = adresse !== null ? Object.assign(this.adresse, adresse) : Object.assign(this.adresse, ADRESSE_DEFAUT);
            this.$nextTick(() => {
                this.emitAdresse(this.adresse);
            })
        }
    },
    computed: {

        afficheSelecteur() {
            return (this.listeAdresses && this.listeAdresses.length > 1) && !this.masqueSelecteur;
        },
        afficheSauvegarde() {
            return this.memorisation && !this.readonly;
        },

        estLivraison () {
            return this.type === 'livraison';
        },
        estFacturation () {
            return this.type === 'facturation';
        },
        adresseValide () {
            const
                nomSaisi        = this.adresse?.nom?.length > 0,
                societeSaisie   = this.adresse?.societe?.length > 0,
                adresse1Saisie  = this.adresse?.adresse1?.length > 0,
                codepostalSaisi = this.adresse?.codepostal?.length > 0,
                villeSaisie     = this.adresse?.ville?.length > 0,
                codepaysValide  = this.adresse?.codepays?.length === 2;

            return (nomSaisi || societeSaisie)
                && adresse1Saisie
                && codepostalSaisi
                && villeSaisie
                && codepaysValide;
        },
        listePaysPourSelect () {
            let keys  = Object.keys(this.listePays),
                len   = keys.length,
                liste = [];
            //note de DF: La boucle décroissante inversait induement le tri alphabétique des pays
            for (let i = 0; i < len; i++) liste.push({value: keys[i], text: this.listePays[keys[i]]});
            return liste;
        },
        listeAdressesPourSelect () {
            let len   = this.listeAdresses.length,
                liste = [];

            for (let i = 0; i < len; i++) {
                liste.push({value: this.listeAdresses[i].id ? this.listeAdresses[i] : null, text: this.listeAdresses[i].intitule});
            }

            return liste;
        }
    },
    methods: {

        getListeAdresses (effectueSelection = true) {
            Api.get(this.urlGetAdresse + '/' + this.type)
                .then(adresses => {
                    //l'entrée vide, destinée à creer une nouvelle adresse n'est ajoutée que si on a le droit de saisie
                    this.listeAdresses = Object.assign([], this.readonly ? [] :  LISTE_ADRESSES_DEFAUT);
                    this.listeAdresses.push(...adresses);
                    if (effectueSelection) {
                        this.assigneAdresseParDefaut();
                        if (this.listeAdresses.length && !this.adresseSelectionnee && this.readonly) this.assignePremiereAdresse()
                    }

                    this.$nextTick(() => {
                        this.$emit('adresse-chargees', adresses); //this.listeAdresses.filter(o => o.id));
                    });
                });
        },
        getListePays() {
            Api.get(this.urlGetPays)
                .then(pays => {
                    this.listePays = pays;
                    this.getListeAdresses(this.utiliserDefaut);
                })
        },
        assigneAdresseParDefaut () {
            let defaut = this.listeAdresses.find(adresse => adresse.defaut === 1);

            if (defaut !== undefined) {
                this.adresseSelectionnee = defaut;
            }
        },
        assignePremiereAdresse () {
            if (!Array.isArray(this.listeAdresses) || this.listeAdresses.length === 0) return
            let defaut = this.listeAdresses[0];
            if (defaut !== undefined) {
                this.adresseSelectionnee = defaut;
            }
        },
        filtreAlphanumerique (val) {
            if (!val) return '';
            let reg = /[^\w\d ()%àâäáãåîïìíôöòóõøùûüúéèêëçÿñÀÂÄÁÃÅÎÏÌÍÔÖÒÓÕØÙÛÜÚÉÈÊËÇŸÑ@&.:\-+/']/gi;
            if (Object.prototype.hasOwnProperty.call(val, 'toString')) {
                val = val.toString();
            }
            else {
                val = String(val);
            }

            return val.replace(reg, '');
        },
        normaliseTelephone (telephone) {
            if (!telephone) return '';
            const len = telephone.length;
            let telephoneNormalise = '';

            for (let i = 0; i < len; i++) {
                const char = telephone[i];
                if (CARACTERES_TELEPHONE_AUTORISES.includes(char)) {
                    telephoneNormalise += telephone[i];
                }
            }

            return telephoneNormalise;
        },
        afficheDialogueSauveAdresse () {
            this.sauveAdresseDialogueOuvert = true;
        },
        cacheDialogueSauveAdresse () {
            this.sauveAdresseDialogueOuvert = false;
        },
        emitAdresse (adresse) {
            let adresseAttrs = Object.assign({}, adresse),
                keys         = Object.keys(adresseAttrs),
                len          = keys.length,
                i            = len;

            for (;i--;) {
                let attr = keys[i];

                let val = this.filtreAlphanumerique(adresseAttrs[attr]);
                if (val !== adresseAttrs[attr]) {
                    adresseAttrs[attr] = val;
                }
            }

            adresseAttrs.valide = Number(this.adresseValide);
            this.$emit('input', adresseAttrs);
        },
        sauveAdresse () {
            let clefs = Object.keys(this.adresse),
                len = clefs.length,
                data = new FormData();

            for (let i = 0; i < len; i++) {
                data.append(clefs[i], this.adresse[clefs[i]]);
            }

            data.append('type', this.type);

            Api.post(this.urlSauveAdresse, data)
                .then(() => {
                    this.ouvreInformationRequete("L'adresse a bien été sauvegardée", 'success');
                    this.cacheDialogueSauveAdresse();
                    this.getListeAdresses(false);
                })
                .catch(err => {
                    let error = [],
                        errors = err.response.data.errors,
                        errKeys = Object.keys(errors);

                    if (errKeys.length > 0) {
                        for (let i = 0; i < errKeys.length; i++) {
                            let champ = errKeys[i];
                            if (Array.isArray(champ)) {
                                error.push(errors[champ].join('<br/>'));
                            }
                            else {
                                error.push(errors[champ]);
                            }
                        }
                    }
                    this.ouvreInformationRequete("Une erreur est survenue durant la sauvegarde de l'adresse :<br/>" + error.join('<br/>'), 'error');
                })
        },
        ouvreInformationRequete (texte, status) {
            this.informationRequete = Object.assign(this.informationRequete, { ouvert: true, texte, status });
        },
        fermeInformationRequete () {
            this.informationRequete.ouvert = false;
        }
    },
    mounted () {
        this.getListePays();

    }
}

</script>
<style lang="scss">
.adresse {
    max-width:300px;
    & .adresse-selecteur {
        margin-bottom:8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        height:48px;
        .adresse-sauve {
            margin-right: 10px;
        }
        .spacer {
            flex-grow: 1;
        }
    }
    & .sans-selecteur {
        display: flex;
        flex-direction: row-reverse;
        align-items: baseline;
    }
    & .obligatoire {
        &.information {
            color: #ff4136;
        }
        & label::after {
            content: '*';
            color: #ff4136;
            font-weight:bold;
            margin-left: 0.5em;
            vertical-align: text-bottom;
        }
    }
}

</style>