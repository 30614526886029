<template>
<v-btn fab dark x-small :color="themecolor" v-bind="$attrs" v-on="$listeners" class="zi-2">
    <slot></slot>
</v-btn>
</template>

<script>
import ThemeMixin from './theme';
export default {
    name: "BtnThemeText",
    mixins: [
        ThemeMixin
    ],
}
</script>

<style scoped>
.zi-2 {
    z-index: 2;
 }

</style>