<template>
    <div class="adresses">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/compte">Mon compte</router-link>
                <span> > </span>
                <router-link to="/commandes">Mes adresses</router-link>
            </div>
            <h1 class="titre">Mes adresses</h1>
        </div>
        <div class="carte-arrondie">
            <div class="adresses-body">
                <div class="adresses-controles">
                    <v-text-field
                        label="Recherche"
                        :append-icon="mdiTextSearch"
                        v-model="recherche"
                        class="saisie"
                    ></v-text-field>
                    <v-text-field
                        :value="elementsParPage"
                        label="par page"
                        type="number"
                        min="-1"
                        max="15"
                        @input="elementsParPage = parseInt($event, 10)"
                        class="saisie shrink"
                    />
                    <div class="spacer"></div>
                    <template v-if="portail.saisie_adresses_livraison || portail.saisie_adresses_facturation">
                        <btn-theme @click="ajoutAdresse">
                            <v-icon>{{ mdiPlus }}</v-icon> Ajouter une adresse
                        </btn-theme>
                    </template>
                </div>
                <v-data-table
                    :headers="headers"
                    :items="adresses"
                    :search="recherche"
                    :loading="chargementEnCours"
                    class="adresses-liste"
                    @page-count="nombreDePages = $event"
                    :page.sync="page"
                    :items-per-page="elementsParPage"
                    hide-default-footer
                >
                    <template v-slot:[`item.nomComplet`]="{ item: adresse }">
                        {{ adresse.societe }} {{ adresse.civilite }} {{ adresse.prenom }} {{ adresse.nom }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item: adresse }">
                        <div class="controles-groupe">
                            <btn-theme-text @click="editAdresse(adresse)" title="Editer l'adresse">
                                <v-icon>{{ mdiFileDocumentEditOutline }}</v-icon>
                            </btn-theme-text>
                            <v-btn fab x-small color="white" class="error ml" @click="supprimeAdresse(adresse)" title="Supprimer l'adresse">
                                <v-icon>{{ mdiTrashCanOutline }}</v-icon>
                            </v-btn>
                            <v-btn dark fab x-small color="primary" title="Voir les détails" class="controle" @click="ouvreDetailAdresse(adresse)">
                                <v-icon>{{ mdiEye }}</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <v-dialog v-model="detailAdresseOuvert" :width="750">
                    <v-card class="detail-adresse-card">
                        <adresse
                            :type="adresseSelectionnee.type"
                            :masqueSelecteur="true"
                            :memorisation="false"
                            :utiliser-defaut="false"
                            :readonly="!edit"
                            v-model="adresseSelectionnee"
                        ></adresse>

                        <div class="detail-adresse-controles">
                            <btn-theme @click="fermeDetailAdresse">
                                Annuler
                            </btn-theme>
                            <btn-theme
                                v-if="edit"
                                @click="sauveAdresse"
                            >
                                Sauvegarder
                            </btn-theme>
                        </div>
                    </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-pagination
                    v-model="page"
                    :length="nombreDePages"
                    v-if="nombreDePages > 1"
                    :total-visible="9"
                ></v-pagination>

            </div>
            <v-snackbar
                top
                right
                :timeout="-1"
                :color="informationRequete.status"
                v-model="informationRequete.ouvert"
            >
                <div v-html="informationRequete.texte"></div>

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="fermeInformationRequete"
                    >Fermer</v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>
<script>

import Api from '../api/api.js';
import {mapGetters} from 'vuex';
import Adresse from "../components/adresse/Adresse";
import BtnTheme from "../components/theme/BtnTheme";
import BtnThemeText from "../components/theme/BtnThemeIcon";
import { mdiEye, mdiTrashCanOutline, mdiFileDocumentEditOutline, mdiPlus, mdiTextSearch } from "@mdi/js";

export default {
    name: 'Adresses',
    components: {
        BtnThemeText,
        Adresse,
        BtnTheme,
    },
    data: () => ({
        getListeAdressesURL: process.env.VUE_APP_API_URL + '/adresse/liste/',
        urlSauveAdresse: process.env.VUE_APP_API_URL + '/adresse/sauve',
        urlSupprimeAdresse: process.env.VUE_APP_API_URL + '/adresse/efface',
        adresses: [],
        headers: [
            { text: 'Type', align: 'left', value: 'type' },
            { text: 'Intitulé', align: 'left', value: 'intitule' },
            { text: 'Société', align: 'left', value: 'societe' },
            { text: 'Nom complet', align: 'left', value: 'nomComplet' },
            { text: '', value: 'actions', sortable: false }
        ],
        adresseSelectionnee: {},
        chargementEnCours: false,
        detailAdresseOuvert: false,
        informationRequete: {
            ouvert: false,
            texte: '',
            status: '',
        },
        recherche: '',
        page: 1,
        nombreDePages: 0,
        elementsParPage: 10,
        edit: false,

        mdiEye,
        mdiTrashCanOutline,
        mdiFileDocumentEditOutline,
        mdiPlus,
        mdiTextSearch
    }),
    computed: {
        ...mapGetters([
            'user',
            'portail'
        ])
    },
    methods: {
        getListeAdresses () {
            this.chargementEnCours = true;
            Api.get(this.getListeAdressesURL).then(adresses => {
                this.adresses = adresses;
                this.chargementEnCours = false;
            })
        },
        ajoutAdresse () {
            this.$router.push({ path: '/adresses/ajout' , query: {mode: 'new'} });
        },
        ouvreDetailAdresse (adresse) {
            this.$router.push({ path: `/adresses/ajout/${adresse.id}`});
           // this.adresseSelectionnee = adresse;
           // this.detailAdresseOuvert = true;
        },
        editAdresse (adresse) {
            //this.edit = this.utilisateurPeutModifier(adresse);
           this.$router.push({ path: `/adresses/ajout/${adresse.id}` , query: {mode: 'edit'} });
            //this.edit = this.utilisateurPeutModifier(adresse);
            //this.adresseSelectionnee = adresse;
            //this.detailAdresseOuvert = true;
        },
        fermeDetailAdresse () {
            this.edit = false;
            this.detailAdresseOuvert = false;
            this.adresseSelectionnee = {};
        },
        utilisateurPeutModifier (adresse) {
            if (!adresse) return false;
            let type = adresse.type;
            if (type === 'facturation' && this.portail.saisie_adresses_facturation) {
                return true;
            }
            return !!(type === 'livraison' && this.portail.saisie_adresses_livraison);

        },
        sauveAdresse () {
            let clefs = Object.keys(this.adresseSelectionnee),
                len = clefs.length,
                data = new FormData();

            if (len === 0) {
                this.ouvreInformationRequete("Merci de renseigner les champs pour sauvegarder l'adresse.", 'error');
                return;
            }

            for (let i = 0; i < len; i++) {
                data.append(clefs[i], this.adresseSelectionnee[clefs[i]]);
            }

            Api.post(this.urlSauveAdresse, data)
                .then(() => {
                    this.getListeAdresses();
                    this.fermeDetailAdresse();
                })
                .catch(err => {
                    let error = [],
                        errors = err.response.data.errors,
                        errKeys = Object.keys(errors);

                    if (errKeys.length > 0) {
                        for (let i = 0; i < errKeys.length; i++) {
                            let champ = errKeys[i];
                            if (Array.isArray(champ)) {
                                error.push(errors[champ].join('<br/>'));
                            }
                            else {
                                error.push(errors[champ]);
                            }
                        }
                    }
                    this.ouvreInformationRequete("Une erreur est survenue durant la sauvegarde de l'adresse :<br/>" + error.join('<br/>'), 'error');
                });
        },
        supprimeAdresse (adresse) {
            if (confirm("Êtes-vous sûr de vouloir supprimer cette adresse ?")) {
                let data = new FormData();
                data.append('id', adresse.id);
                Api.post(this.urlSupprimeAdresse, data)
                    .then(() => {
                        this.getListeAdresses();
                        this.ouvreInformationRequete("L'adresse a bien été supprimé.", 'success');
                    })
                    .catch(() => {
                        this.ouvreInformationRequete("Une erreur est survenue durant la suppression. Veuillez réessayer plus tard ou contacter le support.", 'error')
                    });
            }
        },
        ouvreInformationRequete (texte, status) {
            this.informationRequete = Object.assign(this.informationRequete, { ouvert: true, texte, status });
        },
        fermeInformationRequete () {
            this.informationRequete.ouvert = false;
        }
    },
    mounted () {
        this.getListeAdresses();
    }
}

</script>
<style lang="scss" scoped>
.adresses {
    display: flex;
    flex-direction: column;
    & .adresses-body {
        padding: 12px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        & .adresses-controles {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            & .saisie {
                padding-right: 5px;
                .nbelements {
                    width: 60px;
                }
            }
            & .spacer {
                flex-grow: 1;
            }
            & .selection-periode {

            }
            & .action {
                margin-left: 12px;
                margin-top: 12px;
            }
        }
        & .adresses-liste {
            flex-grow: 1;
        }
    }
    & .controles-groupe {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 5px;
    }
}
.detail-adresse-card {
    padding: 1em;
    & .detail-adresse-controles {
        display: flex;
        justify-content: space-between;
    }
}
</style>